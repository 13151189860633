import { Box, Typography } from "@mui/material";
import { formatPrice } from "../../api/shared/utils";
import React from "react";

export const RebateBox = ({
  discount,
  rebate,
}: {
  discount?: string;
  rebate: number;
}) =>
  rebate <= 0 ? null : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        py: 1,
        borderColor: "background.default",
        borderStyle: "solid",
        borderWidth: "0 0 1px",
      }}
    >
      <Typography flexGrow={1} color="text.primary" variant="body2">
        {discount}
      </Typography>
      <Typography
        flex="0 0 5rem"
        color="text.primary"
        variant="body2"
        fontWeight="bold"
        textAlign="right"
      >
        {formatPrice(-rebate)}
      </Typography>
    </Box>
  );
