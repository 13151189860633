import { Box, Typography } from "@mui/material";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { FC } from "react";
import { GatsbyShop } from "../utils/shop";

export const Banner: FC<{
  name: string;
  baseline: string;
  banner?: GatsbyShop["banner"];
}> = ({ banner, name, baseline }) => {
  return (
    <Box component="header">
      <Box
        id="shop-brand"
        itemProp="brand"
        itemScope
        itemType="http://schema.org/Brand"
        sx={{
          height: "20rem",
          position: "relative",
          overflow: "hidden",
          ".gatsby-image-wrapper": {
            position: "relative",
            display: "block",
            height: "24rem",
          },
        }}
      >
        {banner && banner.childImageSharp && (
          <GatsbyImage alt="" image={banner.childImageSharp.gatsbyImageData} />
        )}
        <Box
          sx={{
            position: "absolute",
            pt: "4rem",
            top: "0",
            right: "0",
            bottom: "0",
            left: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "normal",
            flexDirection: "column",
            color: "common.white",
            textShadow: "0 0 0.3125rem rgba(0,0,0, 0.5)",
            backgroundColor: "rgba(0,0,0,0.25)",
          }}
        >
          <Typography
            itemProp="name"
            variant="h1"
            className="ui-header--name"
            sx={{
              fontSize: {
                xs: "1.875rem",
                md: "2.5rem",
              },
              fontWeight: 500,
              lineHeight: {
                xs: "1.875rem",
                md: "2.5rem",
              },
              marginBottom: "1rem",
              textAlign: "center",
            }}
          >
            {name}
          </Typography>
          <Typography
            sx={{
              fontSize: {
                xs: "0.9375rem",
                md: "1.25rem",
              },
            }}
          >
            {baseline}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
