import React, { FC } from "react";
import { Grid, Tooltip, Typography } from "@mui/material";
import {
  CreditCardOutlined,
  InfoSharp,
  LocalShippingOutlined,
  SvgIconComponent,
  TimerOutlined,
} from "@mui/icons-material";
import { Container } from "../layouts/container";
import { GatsbyShop } from "../utils/shop";

const REASSURANCES: {
  key: keyof GatsbyShop["reassurance"];
  Icon: SvgIconComponent;
}[] = [
  {
    key: "shipping",
    Icon: LocalShippingOutlined,
  },
  {
    key: "delay",
    Icon: TimerOutlined,
  },
  {
    key: "payment",
    Icon: CreditCardOutlined,
  },
];

export const Reassurances: FC<{ shop: GatsbyShop }> = ({ shop }) => {
  return (
    <Container>
      <Grid container sx={{ py: 3, alignItems: "flex-start" }} spacing={1}>
        {REASSURANCES.map(({ key, Icon }) => (
          <Grid
            key={key}
            xs={4}
            justifyContent="center"
            container
            item
            sx={{
              flexDirection: {
                xs: "column",
                md: "row",
              },
              alignItems: "center",
            }}
          >
            <Grid item sx={{ flex: "0 0 2rem" }}>
              <Icon
                sx={{
                  opacity: "0.7",
                  display: "block",
                  textAlign: "right",
                  fontSize: "2rem",
                  mr: {
                    xs: 0,
                    md: "1rem",
                  },
                }}
              />
            </Grid>
            <Grid item>
              <Typography
                paragraph
                sx={{
                  fontSize: "0.875rem",
                  fontWeight: "700",
                  height: "1.5rem",
                  m: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: { xs: "center", md: "flex-start" },
                  textAlign: "center",
                }}
              >
                {shop.reassurance[key].title}
                {shop.reassurance[key]?.information &&
                  shop.reassurance[key]?.information !== "" && (
                    <Tooltip
                      sx={{ ml: 0.5 }}
                      title={
                        <Typography
                          sx={{ whiteSpace: "pre-wrap" }}
                          variant="body2"
                        >
                          {shop.reassurance[key].information!}
                        </Typography>
                      }
                      arrow
                    >
                      <InfoSharp
                        sx={{
                          ml: "0.25rem",
                          display: { xs: "none", sm: "block" },
                        }}
                        fontSize="small"
                        color="primary"
                      />
                    </Tooltip>
                  )}
              </Typography>
              <Typography
                paragraph
                sx={{
                  fontSize: "0.875rem",
                  minHeight: "1.5rem",
                  display: "flex",
                  justifyContent: { xs: "center", md: "flex-start" },
                  textAlign: "center",
                  lineHeight: "1.3",
                  m: 0,
                }}
              >
                {shop.reassurance[key].description}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
