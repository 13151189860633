import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Box,
  Button as ButtonBase,
  Card,
  CardActions,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Button } from "../atoms/button";
import {
  AddCircle,
  CheckCircleOutline,
  RadioButtonUnchecked,
  RemoveCircle,
} from "@mui/icons-material";
import { keyframes } from "@emotion/react";
import {
  CartItem,
  DEFAULT_REBATES,
  Info,
  Price,
  Shipping,
  Validation,
} from "../../api/shared/shop";
import { computePrice, validateCart } from "../../api/shared/cart";
import { formatPrice } from "../../api/shared/utils";
import { GatsbyImage } from "gatsby-plugin-image";
import { RebateBox } from "../molecules/rebate-box";
import { GatsbyProduct, GatsbyShop } from "../utils/shop";

type CartProps = {
  shop: GatsbyShop;
  minimum?: number;
  packing?: number;
  updatable: boolean;
  onCheckout: () => void;
};

export const Cart = ({
  minimum,
  packing,
  updatable,
  onCheckout,
  shop: { products, discounts },
}: CartProps) => {
  const {
    order,
    items,
    add,
    canAdd,
    remove,
    price,
    processing,
    code,
    setCode,
  } = useCart()!;
  const [value, setValue] = useState(code);
  const [error, setError] = useState(false);
  const bottles = items
    .filter(({ product: { capacity } }) => capacity < 150)
    .reduce(
      (quantity, item) =>
        quantity + item.quantity * (item.product.packing ?? 1),
      0
    );
  const magnums = items
    .filter(({ product: { capacity } }) => capacity >= 150)
    .reduce(
      (quantity, item) =>
        quantity + item.quantity * (item.product.packing ?? 1),
      0
    );
  const hasMagnums = !!products.find(({ capacity }) => capacity >= 150);
  const minimumValid = !minimum || bottles >= minimum || magnums >= 1;
  const packingValid = !packing || (bottles >= 0 && bottles % packing === 0);
  const valid =
    minimumValid &&
    ((bottles > 0 && packingValid) || (bottles === 0 && magnums > 0));

  const validateCode = (code: string) => {
    if (
      discounts
        .map((discount) => (discount.trigger === "code" ? discount.code : null))
        .filter((code) => !!code)
        .indexOf(code) >= 0
    ) {
      setCode(code);
      setError(false);
    } else {
      setCode("");
      setError(true);
    }
  };

  const removeCode = () => {
    setCode("");
    setValue("");
  };

  return order ? null : (
    <Card id="cart" elevation={0}>
      <Box sx={{ padding: 1 }}>
        <Typography
          sx={{
            pb: 1,
            borderColor: "background.default",
            borderStyle: "solid",
            borderWidth: "0 0 1px",
          }}
        >
          Votre commande
        </Typography>
        {items.map((item) => (
          <Box
            sx={{
              display: "flex",
              py: item.quantity > 0 ? 1 : 0,
              borderColor: "background.default",
              borderStyle: "solid",
              borderWidth: `0 0 ${item.quantity > 0 ? 1 : 0}px`,
              animation: `${item.quantity > 0 ? visible : hidden} ${
                updatable ? 300 : 0
              }ms linear`,
              height: item.quantity > 0 ? "4.25rem" : "0rem",
              opacity: item.quantity > 0 ? 1 : 0,
            }}
            key={item.product.sku}
          >
            <Box
              sx={{
                width: "2.5rem",
                height: "2.5rem",
                objectFit: "cover",
                mt: 0.25,
                mr: 1,
                overflow: "hidden",
                ".gatsby-image-wrapper": {
                  display: "block",
                  width: "2.5rem",
                  height: "2.5rem",
                },
                img: {
                  borderRadius: "0.25rem",
                },
              }}
            >
              {item.product.image?.childImageSharp && (
                <GatsbyImage
                  image={item.product.image?.childImageSharp?.small}
                  alt={item.product.name}
                />
              )}
            </Box>
            <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
              <Box flexGrow={1}>
                <Typography fontWeight="bold" variant="body2" component="span">
                  {item.product.name}
                </Typography>
                {!!item.product.vintage.length && (
                  <Typography
                    variant="body2"
                    component="span"
                    color="text.secondary"
                  >
                    &nbsp;{item.product.vintage}
                  </Typography>
                )}
                <Typography
                  variant="body2"
                  component="span"
                  color="text.secondary"
                >
                  &nbsp;-&nbsp;{item.product.capacity} cl.
                </Typography>
                {item.product.packing > 1 && (
                  <Typography
                    variant="body2"
                    component="span"
                    color="text.secondary"
                  >
                    &nbsp;-&nbsp;({item.product.packing} bt.)
                  </Typography>
                )}
              </Box>

              <Box
                display="flex"
                flexGrow={1}
                alignItems="center"
                sx={{ ".Mui-disabled": { opacity: 0.5 } }}
              >
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  color="text.secondary"
                  flexGrow={1}
                >
                  {formatPrice(item.product.price)}
                </Typography>
                <Box flex="0 0 1.25rem">
                  {updatable && (
                    <IconButton
                      disabled={item.quantity === 0}
                      sx={{ p: 0 }}
                      onClick={() => remove(item.product)}
                    >
                      <RemoveCircle color="primary" fontSize="small" />
                    </IconButton>
                  )}
                </Box>
                <Typography flex="0 0 2rem" variant="body2" textAlign="center">
                  {item.quantity}
                </Typography>
                <Box flex="0 0 1.25rem">
                  {updatable && (
                    <IconButton
                      sx={{ p: 0 }}
                      onClick={() => add(item.product)}
                      disabled={!canAdd(item.product)}
                    >
                      <AddCircle color="primary" fontSize="small" />
                    </IconButton>
                  )}
                </Box>
                <Typography
                  flex="0 0 5rem"
                  variant="body2"
                  fontWeight="bold"
                  color="text.secondary"
                  textAlign="right"
                >
                  {formatPrice(item.quantity * item.product.price)}
                </Typography>
              </Box>
            </Box>
          </Box>
        ))}
        {!updatable && (
          <Box
            sx={{
              display: "flex",
              py: 1,
              position: "relative",
              borderColor: "background.default",
              borderStyle: "solid",
              borderWidth: "0 0 1px",
            }}
          >
            <TextField
              placeholder="Code promo"
              size="small"
              fullWidth
              value={value}
              onChange={(e) => setValue(e.target.value)}
              disabled={processing || code !== ""}
              error={error}
              required
            />
            <ButtonBase
              sx={{
                position: "absolute",
                zIndex: 1,
                width: "auto",
                height: "2rem",
                top: "50%",
                transform: "translateY(-50%)",
                right: "4px",
                borderRadius: "2px",
              }}
              onClick={() => (code === "" ? validateCode(value) : removeCode())}
              disabled={value.length === 0}
              variant="contained"
              disableElevation
            >
              {code === "" ? "Appliquer" : "Supprimer"}
            </ButtonBase>
          </Box>
        )}
        <RebateBox {...price.productsRebate.unconditional} />
        <RebateBox {...price.productsRebate.amount} />
        <RebateBox {...price.productsRebate.quantity} />
        <RebateBox {...price.productsRebate.code} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            pt: 1,
          }}
        >
          <Typography flexGrow={1} color="text.secondary" variant="body2">
            {bottles + magnums > 0 ? "Sous-total" : "Votre panier est vide"}
          </Typography>
          {bottles + magnums > 0 && (
            <Typography
              flex="0 0 4.5rem"
              variant="body2"
              padding="0 1.25rem"
              textAlign="center"
            >
              {/*quantity*/}
            </Typography>
          )}
          <Typography
            flex="0 0 5rem"
            color="text.secondary"
            variant="body2"
            fontWeight="bold"
            textAlign="right"
          >
            {bottles + magnums > 0 && formatPrice(price.productsSubtotal)}
          </Typography>
        </Box>
      </Box>
      {updatable && (
        <>
          <Box
            sx={{
              px: 1,
            }}
          >
            <Box
              sx={{
                borderStyle: "solid",
                borderColor: "background.default",
                borderWidth: "1px 0 0",
                pt: 1,
              }}
            >
              {minimum && (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                  }}
                >
                  {minimumValid ? (
                    <CheckCircleOutline
                      fontSize="small"
                      color="primary"
                      sx={{ mr: 0.5 }}
                    />
                  ) : (
                    <RadioButtonUnchecked
                      fontSize="small"
                      color="primary"
                      sx={{ mr: 0.5 }}
                    />
                  )}
                  <Typography variant="body2" color="text.primary">
                    {`${
                      hasMagnums ? "1 magnum ou " : ""
                    }${minimum} bouteilles minimum par commande`}
                  </Typography>
                </Box>
              )}
              {hasMagnums && (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    opacity: magnums > 0 ? 1 : 0.5,
                  }}
                >
                  {magnums > 0 ? (
                    <CheckCircleOutline
                      fontSize="small"
                      color="primary"
                      sx={{ mr: 0.5 }}
                    />
                  ) : (
                    <RadioButtonUnchecked
                      fontSize="small"
                      color="primary"
                      sx={{ mr: 0.5 }}
                    />
                  )}
                  <Typography
                    variant="body2"
                    color="text.primary"
                  >{`Commande de magnums à l'unité`}</Typography>
                </Box>
              )}
              {packing && (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    opacity: bottles > 0 ? 1 : 0.5,
                  }}
                >
                  {bottles > 0 && packingValid ? (
                    <CheckCircleOutline
                      fontSize="small"
                      color="primary"
                      sx={{ mr: 0.5 }}
                    />
                  ) : (
                    <RadioButtonUnchecked
                      fontSize="small"
                      color="primary"
                      sx={{ mr: 0.5 }}
                    />
                  )}
                  <Typography
                    variant="body2"
                    color="text.primary"
                  >{`Commande de bouteilles par ${packing}`}</Typography>
                </Box>
              )}
            </Box>
          </Box>
          <CardActions>
            <Button onClick={onCheckout} disabled={!valid}>
              <Typography
                component="span"
                sx={{ display: "inline-flex", alignItems: "center" }}
              >
                <CheckCircleOutline fontSize="small" sx={{ mr: 0.5 }} />
                <Typography component="span">Commander</Typography>
              </Typography>
            </Button>
          </CardActions>
        </>
      )}
    </Card>
  );
};

const visible = keyframes({
  "0%": {
    height: 0,
    opacity: 0,
    padding: 0,
    borderWidth: 0,
  },
  "50%": {
    opacity: 0,
    height: "4.25rem",
    paddingTop: 10,
    paddingBottom: 10,
  },
  "100%": {
    opacity: 1,
  },
});

const hidden = keyframes({
  "0%": {
    opacity: 1,
    height: "4.25rem",
    paddingTop: 10,
    paddingBottom: 10,
  },
  "50%": {
    opacity: 0,
    height: "4.25rem",
    paddingTop: 10,
    paddingBottom: 10,
  },
});

const CartContext =
  createContext<
    | undefined
    | {
        add: (product: GatsbyProduct) => Promise<void>;
        canAdd: (product: GatsbyProduct) => boolean;
        remove: (product: GatsbyProduct) => Promise<void>;
        clear: () => Promise<void>;
        readonly shop: string;
        readonly items: CartItem<GatsbyProduct>[];
        readonly regions: string[];
        readonly shipping: Shipping;
        setShipping: (shipping: Shipping) => void;
        readonly code: string;
        setCode: (code: string) => void;
        readonly info: Info;
        setInfo: (info: Info) => void;
        readonly price: Price;
        readonly processing: boolean;
        setProcessing: (processing: boolean) => void;
        readonly order?: string;
        setOrder: (order: string) => void;
        readonly validation: Validation;
        readonly pristine: boolean;
        setPristine: (pristine: boolean) => void;
      }
  >(undefined);

const loadCart = (id: string): CartItem<GatsbyProduct>[] => {
  if (typeof window !== "undefined") {
    const data = sessionStorage.getItem(`cart-${id}`);
    if (data)
      return (JSON.parse(data) as CartItem<GatsbyProduct>[]).filter(
        ({ quantity }) => quantity > 0
      );
  }
  return [];
};

const saveCart = (id: string, items: CartItem<GatsbyProduct>[]) => {
  if (typeof window !== "undefined") {
    /** TODO check consent **/
    sessionStorage.setItem(`cart-${id}`, JSON.stringify(items));
  }
  return [];
};

const notifyCartEvent = (
  event: "add_to_cart" | "remove_from_cart",
  product: GatsbyProduct
) => {
  try {
    const dataLayer: any[] = (window as any).dataLayer;
    if (dataLayer) {
      dataLayer.push({ ecommerce: null });
      dataLayer.push({
        event,
        ecommerce: {
          items: [
            {
              item_id: product.sku,
              item_name: product.name,
              item_category: product.color,
              item_variant: product.vintage,
              quantity: 1,
              price: product.price / 100,
              currency: "EUR",
            },
          ],
        },
      });
    }
  } catch {
    // Do nothing
  }
};

export const CartProvider = ({
  shop,
  children,
}: PropsWithChildren<{ shop: GatsbyShop }>) => {
  const [validation, setValidation] = useState<Validation>({ valid: true });
  const [pristine, setPristine] = useState(true);
  const [processing, setProcessing] = useState<boolean>(false);
  const [order, setOrder] = useState<string | undefined>();
  const [price, setPrice] = useState<Price>({
    products: 0,
    productsSubtotal: 0,
    productsRebate: DEFAULT_REBATES,
    shipping: 0,
    shippingSubtotal: 0,
    shippingRebate: DEFAULT_REBATES,
    surcharge: 0,
    vat: 0,
    total: 0,
  });
  const [items, setItems] = useState<CartItem<GatsbyProduct>[]>([]);
  const [info, setInfo] = useState<Info>({
    name: "",
    email: "",
    phone: "",
    birthDate: "",
    birthMonth: "",
    birthYear: "",
  });
  useEffect(() => setItems(loadCart(shop.id)), []);
  const [code, setCode] = useState<string>("");
  const bottles = items
    .filter(({ product: { capacity } }) => capacity < 150)
    .reduce(
      (quantity, item) =>
        quantity + item.quantity * (item.product.packing ?? 1),
      0
    );
  const magnums = items
    .filter(({ product: { capacity } }) => capacity >= 150)
    .reduce(
      (quantity, item) =>
        quantity + item.quantity * (item.product.packing ?? 1),
      0
    );

  const regions = Array.from(
    new Set(shop.shipping.home.zones.map(({ name }) => name))
  );

  const [shipping, setShipping] = useState<Shipping>({
    mode: "home",
    address: "",
    zipcode: "",
    city: "",
    region: (regions.length > 0 && regions[0]) || "",
    comment: "",
  });

  const canAdd = (product: GatsbyProduct) => {
    const item = items.find(({ product: { sku } }) => sku === product.sku);
    if (product.capacity >= 150) {
      return magnums < 5;
    } else {
      return (
        bottles + (item?.product?.packing ?? 1) <=
          (shop.shipping.maximum ?? Infinity) &&
        (item?.quantity ?? 1) < (product.limit || Infinity)
      );
    }
  };

  const add = async (product: GatsbyProduct) => {
    if (canAdd(product)) {
      const item = items.find(({ product: { sku } }) => sku === product.sku);
      if (item) {
        item.quantity++;
        setItems([...items]);
      } else {
        setItems([...items, { product, quantity: 1 }]);
      }
      notifyCartEvent("add_to_cart", product);
    }
  };

  const remove = async (product: GatsbyProduct) => {
    const item = items.find(({ product: { sku } }) => sku === product.sku);
    if (item) {
      item.quantity = Math.max(0, item.quantity - 1);
      setItems([...items]);
      notifyCartEvent("remove_from_cart", product);
    }
  };

  const clear = async () => {
    setItems([]);
  };

  useEffect(() => {
    saveCart(shop.id, items);
  }, [shop, items]);

  useEffect(() => {
    setPrice(
      computePrice(
        items,
        code,
        shop,
        shipping.region,
        shipping.zipcode,
        shipping.mode
      )
    );
  }, [shop, items, code, shipping]);

  useEffect(() => {
    if (!pristine) {
      setValidation(validateCart(shipping, info, shop));
    }
  }, [shop, items, shipping, info, pristine]);

  return (
    <CartContext.Provider
      value={{
        add,
        canAdd,
        remove,
        clear,
        shop: shop.id,
        items,
        regions,
        info,
        setInfo,
        shipping,
        setShipping,
        code,
        setCode,
        price,
        processing,
        setProcessing,
        order,
        setOrder,
        validation,
        pristine,
        setPristine,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
