export type BaseShop = {
  id: string;
  enabled: boolean;
  name: string;
  customDomain: boolean;
  domain: string;
  baseline: string;
  color: string;
  url: string;
  show: boolean;
  email: string;
  phone: string;
  fees: number;
  legal: {
    company: string;
    capital: string;
    registeredOffice: string;
    phoneNumber: string;
    siret: string;
    intracommunityVAT: string;
    responsible: string;
    contact: string;
  };
  shipping: {
    minimum: number;
    maximum: number;
    packing: number;
    home: {
      enabled: boolean;
      method: string;
      zones: Zone[];
      surcharges: Surcharge[];
    };
    clickAndCollect: {
      enabled: boolean;
      address: string;
      zipcode: string;
      city: string;
      opening: Opening;
    };
    fairs: Fair[];
  };
  reassurance: {
    [key in "shipping" | "delay" | "payment"]: {
      title: string;
      description: string;
      information?: string;
    };
  };
  exocep: {
    account: string;
    env: string;
  };
  stripe: {
    account: string;
    env: string;
  };
  products: BaseProduct[];
  discounts: Discount[];
};

export type GenericShop<I, P> = Omit<BaseShop, "products"> & {
  logo?: I;
  banner?: I;
  products: P[];
};

export type Shop = GenericShop<string, Product>;

export type BaseProduct = {
  sku: string;
  ean?: string;
  name: string;
  title: string;
  vintage: string;
  color: keyof typeof COLORS;
  location: string;
  capacity: number;
  price: number;
  datasheet: string;
  packing: number;
  container: string;
  grape: string[];
  alcohol: number;
  show: boolean;
  inStock: boolean;
  limit?: number;
};

export type GenericProduct<I> = BaseProduct & { image?: I };

export type Product = GenericProduct<string>;

export type Opening = {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  holidays: boolean;
  hours: string;
};

export type Discount = {
  name: string;
  start: string;
  end: string;
} & (
  | {
      trigger: "amount";
      amount: number;
    }
  | {
      trigger: "quantity";
      quantity: number;
    }
  | {
      trigger: "code";
      code: string;
    }
  | {
      trigger: "unconditional";
      code: string;
    }
) &
  (
    | {
        discount: "products_amount_rebate";
        productsAmount: number;
      }
    | {
        discount: "products_percentage_rebate";
        productsPercentage: number;
      }
    | {
        discount: "products_perbottle_rebate";
        productsPerbottleAmount: number;
      }
    | {
        discount: "products_perbottle_price";
        productsPerbottlePrice: number;
      }
    | {
        discount: "shipping_amount_rebate";
        shippingAmount: number;
      }
    | {
        discount: "shipping_percentage_rebate";
        shippingPercentage: number;
      }
    | {
        discount: "shipping_perbottle_price";
        shippingPerbottlePrice: number;
      }
  );

export type Checked<T> = Required<
  T extends number | string | boolean | Array<number | string | boolean>
    ? boolean
    : T extends Array<T>
    ? Checked<T>[]
    : { [k in keyof T]: Checked<T[k]> }
>;

export const validate = (o: any): boolean => {
  return typeof o === "boolean"
    ? o
    : Object.values(o ?? {})
        .map(validate)
        .reduce((a, v) => a && v, true);
};

export const validateShop = (shop: Shop): Checked<Shop> => {
  return {
    id: /^[a-z]+(?:-[a-z]+)*$/.test(shop.id),
    name: !!shop.name?.length,
    color: /^#([a-f0-9]{3}|[a-f0-9]{4}|[a-f0-9]{6})$/i.test(shop.color),
    logo: true,
    banner: true,
    baseline: true,
    customDomain: true,
    domain: true,
    enabled: true,
    show: true,
    fees: shop.fees >= 0,
    url: true,
    email: !shop.email.length || /^\S+@\S+\.\S{2,}$/i.test(shop.email),
    phone: true,
    exocep: { account: true, env: true },
    stripe: { account: true, env: true },
    legal: {
      company: true,
      capital: true,
      registeredOffice: true,
      phoneNumber: true,
      siret: true,
      intracommunityVAT: true,
      responsible: true,
      contact: true,
    },
    products: (shop.products || []).map((product, index) => ({
      sku:
        !!product.sku.length &&
        !shop.products.find(({ sku }, i) => index !== i && sku === product.sku),
      name: !!product.name.length,
      image: true,
      ean: true,
      title: true,
      vintage: true,
      color: true,
      location: true,
      capacity: product.capacity >= 0,
      price: product.price >= 0,
      datasheet: true,
      packing: product.packing >= 0,
      container: true,
      grape: true,
      alcohol: product.alcohol >= 0 && product.alcohol <= 100,
      show: true,
      inStock: true,
      limit: true,
    })),
    discounts: (shop.discounts || []).map((discount) => ({
      name: !!discount.name?.length,
      start: /^\d{4}-\d{2}-\d{2}$/.test(discount.start),
      end: /^\d{4}-\d{2}-\d{2}$/.test(discount.end),
      trigger: true,
      code: discount.trigger !== "code" || !!discount.code?.length,
      amount: discount.trigger !== "amount" || discount.amount >= 0,
      quantity: discount.trigger !== "quantity" || discount.quantity >= 0,
      discount: true,
      productsAmount:
        discount.discount !== "products_amount_rebate" ||
        discount.productsAmount >= 0,
      productsPercentage:
        discount.discount !== "products_percentage_rebate" ||
        discount.productsPercentage >= 0,
      productsPerbottlePrice:
        discount.discount !== "products_perbottle_price" ||
        discount.productsPerbottlePrice >= 0,
      productsPerbottleAmount:
        discount.discount !== "products_perbottle_rebate" ||
        discount.productsPerbottleAmount >= 0,
      shippingAmount:
        discount.discount !== "shipping_amount_rebate" ||
        discount.shippingAmount >= 0,
      shippingPercentage:
        discount.discount !== "shipping_percentage_rebate" ||
        discount.shippingPercentage >= 0,
      shippingPerbottlePrice:
        discount.discount !== "shipping_perbottle_price" ||
        discount.shippingPerbottlePrice >= 0,
    })),
    reassurance: {
      delay: {
        title: !!shop.reassurance?.delay?.title?.length,
        description: !!shop.reassurance?.delay?.description?.length,
        information: true,
      },
      payment: {
        title: !!shop.reassurance?.payment?.title?.length,
        description: !!shop.reassurance?.payment?.description?.length,
        information: true,
      },
      shipping: {
        title: !!shop.reassurance?.shipping?.title?.length,
        description: !!shop.reassurance?.shipping?.description?.length,
        information: true,
      },
    },
    shipping: {
      minimum: shop.shipping.minimum >= 0,
      maximum:
        shop.shipping.maximum >= 0 &&
        shop.shipping.maximum >= shop.shipping.minimum,
      packing: shop.shipping.packing >= 0,
      home: {
        enabled: true,
        method: true,
        zones: (shop.shipping.home.zones || []).map((zone) => ({
          name: !!zone.name.length,
          exclusions: true,
          prices: (zone.prices || []).map((price) => ({
            label: true,
            min: price.min >= 0,
            max: price.max >= 0,
            price: price.price >= 0,
          })),
        })),
        surcharges: (shop.shipping.home.surcharges || []).map((surcharge) => ({
          name: !!surcharge.name.length,
          inclusions: true,
          price: surcharge.price >= 0,
        })),
      },
      clickAndCollect: {
        enabled: true,
        address: true,
        zipcode: true,
        city: true,
        opening: {
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true,
          saturday: true,
          sunday: true,
          holidays: true,
          hours: true,
        },
      },
      fairs: (shop.shipping.fairs || []).map((fair) => ({
        name: !!fair.name.length,
        start: /^\d{4}-\d{2}-\d{2}$/.test(fair.start),
        end: /^\d{4}-\d{2}-\d{2}$/.test(fair.end),
        closing: true,
        address: true,
        zipcode: true,
        city: true,
      })),
    },
  };
};

export type Zone = {
  name: string;
  exclusions: string[];
  prices: ZonePrice[];
};

export type ZonePrice = {
  label: string;
  min: number;
  max: number;
  price: number;
};

export type Surcharge = {
  name: string;
  inclusions: string[];
  price: number;
};

export type Fair = {
  name: string;
  start: string;
  end: string;
  closing: string;
  address: string;
  zipcode: string;
  city: string;
};

export type CartItem<P extends BaseProduct> = { quantity: number; product: P };

export type Shipping = {
  mode: "home" | "estate" | "fair";
  fair?: string;
  address: string;
  zipcode: string;
  city: string;
  region: string;
  day?: string;
  comment?: string;
};

export type Info = {
  name: string;
  email: string;
  phone: string;
  birthDate: string;
  birthMonth: string;
  birthYear: string;
};

export type Rebates = {
  [key in Discount["trigger"]]: { discount?: string; rebate: number };
};

export const DEFAULT_REBATES: Rebates = {
  code: {
    rebate: 0,
  },
  amount: {
    rebate: 0,
  },
  quantity: {
    rebate: 0,
  },
  unconditional: {
    rebate: 0,
  },
};

export type Price = {
  products: number;
  productsSubtotal: number;
  productsRebate: Rebates;
  shipping: number;
  shippingSubtotal: number;
  shippingRebate: Rebates;
  surcharge: number;
  vat: number;
  total: number;
};

export type Order = Shipping &
  Info & {
    oid?: string;
    shop: string;
    code?: string;
    items: CartItem<BaseProduct>[];
  };

export type Validation = {
  valid: boolean;
  addressError?: string;
  zipcodeError?: string;
  cityError?: string;
  regionError?: string;
  nameError?: string;
  mailError?: string;
  phoneError?: string;
  birthError?: string;
};

export const COLORS = {
  rouge: "Vin rouge",
  blanc: "Vin blanc",
  rose: "Vin rosé",
};
