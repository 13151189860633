export const formatPrice = (value: number) => {
  if (isNaN(value)) {
    return value;
  }
  return Intl.NumberFormat("fr", {
    style: "currency",
    currency: "EUR",
  })
    .format(value / 100)
    .replace(/\s+/, "\u00A0");
};

export const formatCapacity = (value: number) => {
  if (isNaN(value)) {
    return value;
  }
  return value >= 100 ? value / 100 + "\u00a0l" : value + "\u00a0cl";
};
