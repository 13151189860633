import {
  BaseProduct,
  BaseShop,
  CartItem,
  DEFAULT_REBATES,
  Fair,
  Info,
  Price,
  Rebates,
  Shipping,
  Shop,
  Validation,
} from "./shop";

export const findDiscounts = (
  shop: Shop,
  products: number,
  shipping: number,
  quantity: number,
  code: string | undefined
): {
  [key in "products" | "shipping"]: Rebates;
} => {
  const now = Date.now();
  return (
    shop.discounts
      // 1 - Keep only active discounts
      .filter(
        ({ start, end }) => Date.parse(start) <= now && Date.parse(end) >= now
      )
      // 2 - Keep only triggered discounts
      .filter((discount) => {
        switch (discount.trigger) {
          case "code":
            return discount.code === code;
          case "amount":
            return products >= discount.amount * 100;
          case "quantity":
            return quantity >= discount.quantity;
          case "unconditional":
            return true;
          default:
            return false;
        }
      })
      // 3 - Select best discounts for each trigger type
      .reduce<
        {
          [key in "products" | "shipping"]: Rebates;
        }
      >(
        (acc, discount) => {
          let rebate = 0;
          let target: "products" | "shipping";
          switch (discount.discount) {
            case "products_amount_rebate":
              rebate = discount.productsAmount * 100;
              target = "products";
              break;
            case "products_percentage_rebate":
              rebate = (discount.productsPercentage * products) / 100;
              target = "products";
              break;
            case "products_perbottle_rebate":
              rebate = discount.productsPerbottleAmount * quantity * 100;
              target = "products";
              break;
            case "products_perbottle_price":
              rebate =
                products - discount.productsPerbottlePrice * quantity * 100;
              target = "products";
              break;
            case "shipping_amount_rebate":
              rebate = discount.shippingAmount * 100;
              target = "shipping";
              break;
            case "shipping_percentage_rebate":
              rebate = (discount.shippingPercentage * shipping) / 100;
              target = "shipping";
              break;
            case "shipping_perbottle_price":
              rebate =
                shipping - discount.shippingPerbottlePrice * quantity * 100;
              target = "shipping";
              break;
          }
          if (target && rebate > acc[target][discount.trigger].rebate) {
            acc[target][discount.trigger] = {
              discount: discount.name,
              rebate,
            };
          }
          return acc;
        },
        {
          products: { ...DEFAULT_REBATES },
          shipping: { ...DEFAULT_REBATES },
        }
      )
  );
};

export const computePrice = (
  items: CartItem<BaseProduct>[],
  code: string | undefined,
  shop: BaseShop,
  region: string,
  zipcode: string,
  mode: Shipping["mode"]
): Price => {
  const bottles = items.reduce(
    (total, { product: { packing }, quantity }) => total + packing * quantity,
    0
  );
  const products = items.reduce(
    (total, { quantity, product: { price } }) => total + quantity * price,
    0
  );
  let delivery = 0;
  let surcharge = 0;
  if (mode === "home") {
    delivery =
      shop.shipping.home.zones
        .filter(({ name }) => name === region)
        .flatMap(({ prices }) => prices)
        .find(({ min, max }) => bottles >= min && bottles <= max)?.price ?? 0;
    surcharge =
      shop.shipping.home.surcharges?.find(
        (rule) =>
          rule.inclusions.findIndex((zip) => zipcode.startsWith(zip)) >= 0
      )?.price || 0;
  }

  const shipping = delivery + surcharge;

  const rebates = findDiscounts(shop, products, shipping, bottles, code);

  const productsSubtotal = Math.max(
    0,
    products -
      Object.values(rebates.products).reduce(
        (sum, { rebate }) => sum + rebate,
        0
      )
  );
  const shippingSubtotal = Math.max(
    0,
    shipping -
      Object.values(rebates.shipping).reduce(
        (sum, { rebate }) => sum + rebate,
        0
      )
  );

  const total = productsSubtotal + shippingSubtotal;
  const vat = (total / (1 + 0.2)) * 0.2;

  return {
    products,
    productsSubtotal,
    productsRebate: rebates.products,
    shipping,
    surcharge,
    shippingSubtotal,
    shippingRebate: rebates.shipping,
    vat,
    total,
  };
};

export const computeAge = (birthDate: Date) => {
  const today = new Date();
  const age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    return age - 1;
  }
  return age;
};

export const validateCart = (
  shipping: Shipping,
  info: Info,
  shop: BaseShop
): Validation => {
  const addressError =
    shipping.mode !== "home" || shipping.address.trim().length > 0
      ? undefined
      : "Saisissez votre adresse";

  const zipcodeError =
    shipping.mode !== "home" || /^\d{5}$/.test(shipping.zipcode.trim())
      ? undefined
      : "Code postal invalide";

  const cityError =
    shipping.mode !== "home" || shipping.city.trim().length > 0
      ? undefined
      : "Saisissez votre ville";

  const nameError =
    info.name.trim().length > 0 ? undefined : "Saisissez votre nom";

  const mailError =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      info.email.trim()
    )
      ? undefined
      : "Email invalide";

  const phoneError = /^(?:\+33|0)(?:\s?\d){9}$/.test(info.phone.trim())
    ? undefined
    : "Téléphone invalide";

  const date = new Date(+info.birthYear, +info.birthMonth - 1, +info.birthDate);
  const birthError =
    info.birthDate.length > 0 &&
    info.birthMonth.length > 0 &&
    info.birthYear.length > 0 &&
    Boolean(+date) &&
    date.getDate() == +info.birthDate
      ? computeAge(date) < 18
        ? "Vous n'avez pas l'âge requis"
        : undefined
      : "Date de naissance invalide";

  const regionError =
    shipping.mode === "home" &&
    shop?.shipping.home.zones.filter(
      ({ name, exclusions }) =>
        name === shipping.region &&
        !exclusions.find((excluded) => shipping.zipcode.startsWith(excluded))
    ).length === 0
      ? "Votre code postal ne correspond pas à cette zone"
      : undefined;

  return {
    addressError,
    zipcodeError,
    regionError,
    cityError,
    nameError,
    mailError,
    phoneError,
    birthError,
    valid:
      !addressError &&
      !zipcodeError &&
      !cityError &&
      !nameError &&
      !regionError &&
      !mailError &&
      !phoneError &&
      !birthError,
  };
};

export const findFairs = (shop: BaseShop): Fair[] => {
  const date = new Date();
  const now = Date.parse(
    `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
  );
  return shop.shipping.fairs
    .sort(({ start: a }, { start: b }) => Date.parse(a) - Date.parse(b))
    .filter((fair) => now <= Date.parse(fair.closing));
};
