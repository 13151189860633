import React, { PropsWithChildren } from "react";
import { Box, Grid, Link, Typography } from "@mui/material";
import { Container } from "../layouts/container";
import { SxProps } from "@mui/system";
import {StaticImage} from "gatsby-plugin-image";

type FooterProps = {
  root: string;
  name: string;
  url: string;
};

const FooterLink = ({
  href,
  children,
}: PropsWithChildren<{ href: string }>) => {
  return (
    <Link
      sx={{
        color: "text.primary",
        cursor: "pointer",
        textDecoration: "none",
        ":hover": {
          textDecoration: "underlines",
        },
      }}
      href={href}
    >
      <Typography component="span" variant="body2">
        {children}
      </Typography>
    </Link>
  );
};

const sep: SxProps = {
  ":before": {
    content: "'|'",
    mx: 1,
    opacity: 0.25,
    display: {
      xs: "none",
      md: "inline-block",
    },
  },
};

export const Footer = ({ name, url, root }: FooterProps) => (
  <Box component="footer" sx={{ py: "1rem" }}>
    <Container
      sx={{
        py: "1rem",
        px: 1,
        mb: "1rem",
        borderBottom: "1px solid rgba(0,0,0,0.1)"
      }}
    >
      <Typography variant="body2" textAlign="center">
        L'abus d'alcool est dangereux pour la santé. Consommer avec modération.
      </Typography>
      <Box sx={{display: "flex", mt: "1rem", justifyContent: "center"}}>
        <StaticImage src="../../static/bandeau.jpg" alt="" />
      </Box>
    </Container>
    <Container>
      <Grid
        container
        sx={{
          textAlign: { xs: "center", md: "left" },
        }}
      >
        <Grid item xs={12} md="auto">
          <FooterLink href={`${root}/cgv`}>CGV</FooterLink>
        </Grid>
        <Grid item sx={sep} xs={12} md="auto">
          <FooterLink href={`${root}/mentions-legales`}>
            Mentions légales
          </FooterLink>
        </Grid>
        <Grid item sx={sep} xs={12} md="auto">
          <FooterLink href={`${root}/livraison`}>Livraison</FooterLink>
        </Grid>
        <Grid item flexGrow={1} sx={{ display: { xs: "none", sm: "block" } }} />
        <Grid item xs={12} md="auto">
          <Typography component="span" variant="body2">
            &copy; {new Date().getFullYear()}{" "}
            <FooterLink href={url}>{name}</FooterLink>
          </Typography>
        </Grid>
        <Grid item xs={12} md="auto">
          <Typography component="span" variant="body2" sx={sep}>
            Propulsé par{" "}
            <FooterLink href="https://exoshop.app">Exoshop</FooterLink>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  </Box>
);
